import { Html } from "@react-three/drei"
function Loading() {
    return(
        <Html center>
            <div className="text-center">
                <span className="loading loading-infinity loading-4xl bg-white"></span> 
                <p className=" font-extralight font-roboto text-white text-2xl">Loading...</p>
            </div>
        </Html>
    )
}

export { Loading }