import { Billboard, Html, Text } from '@react-three/drei'
import { fonts } from '../utils/States'
import { Suspense, useEffect, useState } from 'react'
import { animated, useSpringRef, useSpringValue, useTransition } from '@react-spring/three'
import { AboutContent } from './AboutContent'
import { ProjectsContent } from './ProjectsContent'
import { extend, invalidate,  } from '@react-three/fiber'

const segments = [
    // TODO: this component causes a load lag when an option is selected for the first time. 
    // Currently avoided by loading an invisible object
    ({style}) => <></>,
    ({style}) => <AboutContent key={"about-content"} style={style}/>,
    ({style}) => <ProjectsContent key={"projects-content"} style={style}/>,
    ({style}) => <AboutContent key={"contact-content"}style={style}/>,
]

const Content = ({ index }) => {
    const transRef = useSpringRef()
    const transitions = useTransition(index, {
        ref: transRef,
        from: { opacity: 0.0, position: [-0.5,0.0,-3.0]},
        enter: {  delay: 1000, config: { 
        }, opacity: 1.0, position: [-0.5,0.0,-3.0]},
        leave: { config: {
        }, opacity: 0.0, position: [-0.5,0.0,-3.0]},
    })
    useEffect(() => {
        transRef.start()
    },[index])
    
    return (
        <>
            { transitions((style, i) => {
                const Segment = segments[ i ]
                return <Suspense><Segment style={{index,...style}}/></Suspense>
            } ) }
        </>
    )


}


export { Content }