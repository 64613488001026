import { extend, useFrame, useThree, createPortal, Canvas, useLoader } from "@react-three/fiber"
import { Bloom, DepthOfField, EffectComposer, Noise, Glitch } from '@react-three/postprocessing'
import { GlitchMode } from 'postprocessing'
import { Cloud, Sky, Stars, useCursor, useFBO, OrbitControls, FirstPersonControls, OrthographicCamera, useHelper, Html, useCamera, Billboard, useTexture, Reflector, MeshReflectorMaterial } from "@react-three/drei"
import * as THREE from 'three'
import { Text, Svg, Line } from "@react-three/drei"
import { useMemo, useRef, useEffect, Children, Suspense, useState, useTransition, useLayoutEffect } from "react"
import {FBOParticles} from "./FBOParticles"
import { useControls } from "leva"
import { gsap,  } from "gsap"
import { useSpring, animated, to, useResize, useSpringValue, useSpringRef, useChain, SpringValue, } from "@react-spring/three"
import CustomEase from 'gsap/CustomEase'
import { states, fonts, svgs } from './utils/States'
import { Perf } from 'r3f-perf'
import { Menu } from './menu/Menu'
import { Loading } from "./Loading"
import { ParallaxControls } from './controls/ParallaxControls'
import { AboutContent } from "./menu/AboutContent"
import { ProjectsContent } from "./menu/ProjectsContent"
import { Content } from "./menu/Content"
// const  AnimatedFBOParticles = animated(FBOParticles)

export default function Experience() {
    const [ selectedIndex, setSelectedIndex ] = useState(0)
    const [ loaded, setLoaded ] = useState(false)
    const stateRef = useRef(states.home)
    
    /**
     * Debug
     */
    // const {
    //     pointsPos,
    //     frequency,
    //     x0z,
    //     x1,
    //     y0,
    //     y1,
    //     z1,
    //     stretch,
    //     blackHoleRad,
    //     extra,
    // }  = useControls({
    //     pointsPos: {
    //         value: {x: 4.0, y: 0.0, z: -4.0},
    //         min: -10.0,
    //         max: 10.0,
    //         step: 0.1
    //      },
    //      frequency: {
    //         value: 0.25,
    //         min: 0.0,
    //         max: 1.0,
    //         step: 0.001
    //     },
    //     x0z: {
    //       value: 4.0,
    //       min: -4.0,
    //       max: 1.0,
    //       step: 0.1
    //    },
    //     x1: {
    //       value: {x: 1.0, y: 1.0},
    //       step: 0.1
    //    },
    //     y0: {
    //       value: {x: 1.0, y: 1.0},
    //       min: -4.0,
    //       max: 4.0,
    //       step: 0.1
    //    },
    //     y1: {
    //       value: {x: 1.0, y: 1.0},
    //       min: -4.0,
    //       max: 4.0,
    //       step: 0.1
    //    },
    //     z1: {
    //       value: {x: 1.0, y: 1.0},
    //       min: -4.0,
    //       max: 4.0,
    //       step: 0.1
    //    },
    //     stretch: {
    //       value: {x: 3.0, y: 1.5, z: 1.0},
    //       min: -4.0,
    //       max: 4.0,
    //       step: 0.1
    //    },
    //    blackHoleRad: {
    //        value: 0.0,
    //        min: -4.0,
    //        max: 4.0,
    //        step: 0.01
    //     },
    //     extra: {
    //       value: {x: -7.5, y: 3.0, z: -1.8},
    //       min: -10.0,
    //       max: 10.0,
    //       step: 0.1
    //    },
    // })

    /**
     * Handle keyboard controls
     */
    let hasHole  = useRef(false)
    const toggleBlackHole  = () => {
        hasHole = !hasHole
    }
    useEffect(() => {
        gsap.registerPlugin(CustomEase)
        const handleKeyDown = (e)  => {
            if (e.repeat) return;
            switch (e.key) {
                case '1': {
                goToHome()
                break;
                }
                case '2': {
                goToAbout()
                break;   
                }
                case '3': {
                goToProjects()    
                break;   
                }
                case '4': {
                goToContact()
                break;   
                }
                case '5': {
                    // setVisIndex([false, false, false, false])
                    setSelectedIndex(1)
                    break;   
                }
                case '6': {
                    // setVisIndex([false, true, false, false])
                    setSelectedIndex(2)

                break;   
                }      
             }
         }
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
         }
    },[])

    /**
     * View Transitions
     */
    const goToHome = () => {
        // tweenState(states.home)
        setSelectedIndex(0)
    }
    const goToAbout = () => {
        // tweenState(states.about)
        // setVisIndex([false, true, false, false])
        setSelectedIndex(1)
    }
    const goToProjects = () => {
        // tweenState(states.projects)
        setSelectedIndex(2)
        // setVisIndex([false, false, true, false])
    }
    const goToContact = () => {
        // tweenState(states.contact)
        setSelectedIndex(3)
    }
    // const uniforms = useMemo(() => 
    // {
    //         return {
    //             uPointsPos: pointsPos ,
    //             uFrequency: frequency,
    //             uX0z: x0z,
    //             uX1: x1,
    //             uY0: y0,
    //             uY1: y1,
    //             uZ1: z1,
    //             uStretch: stretch,
    //             uBlackHoleRad: blackHoleRad,
    //             uExtra: extra,
    //         }
    // }, [pointsPos,
    //     frequency,
    //     x0z,
    //     x1,
    //     y0,
    //     y1,
    //     z1,
    //     stretch,
    //     blackHoleRad,
    //     extra,])

    function Floor() {
        const [floor, normal] = useTexture(['./assets/img/SurfaceImperfections003_1K_var1.jpg', './assets/img/SurfaceImperfections003_1K_Normal.jpg'])
        return (
            <mesh rotation={[stuff2.x,stuff2.y,stuff2.z]}
            position={[stuff.x,stuff.y ,stuff.z]} > 
                <planeGeometry args={[30,20]} />
                <MeshReflectorMaterial resolution={1024} mirror={1} blur={[600, 100]} mixBlur={12} mixStrength={1.5} color={color} metalness={0} roughnessMap={floor} normalMap={normal} normalScale={[5,5]} reflectorOffset={0.2} camera />
            </mesh>
        )
    }
        //   <Reflector resolution={1024} args={[20, 20]} {...props}  >
        //     {(Material, props) => <Material color="#f0f0f0" metalness={0} roughnessMap={floor} normalMap={normal} normalScale={[5, 5]} {...props}/>}
        //   </Reflector>


        // useEffect(() => {
        //     setTimeout(() => {
        //         setSelectedIndex(2)
        //     },1000)
        // },[])
      const {stuff, stuff2 } = useControls({
        stuff: {
            value: {x: 0,y: -3.13,z: 0},
            min: -10,
            max: 10,
            step: 0.01
        },
        stuff2: {
            value: {x: -Math.PI / 2,y: 0,z: Math.PI / 2},
            min: -4,
            max: 4,
            step: 0.01
        }
      })

      function OnFinish() {
        useLayoutEffect(() => setLoaded(true))
        return <></>
      }

    return (
        <>
            <ambientLight/>
            <FBOParticles
            index={selectedIndex} isVisible={loaded}/>
            <Suspense fallback={ <Loading/> } >
                <OnFinish/>
                <Suspense>
                    <Menu  onHomeClick={goToHome} onAboutClick={goToAbout} onProjectsClick={goToProjects} onContactClick={goToContact} 
                    position={([-7.5,3.0,-1.8])}/>    
                </Suspense>
                <Suspense>
                    <Content index={selectedIndex} />
                </Suspense>
            </Suspense>
        </>
    );
};




    


