
import {  useLoader } from '@react-three/fiber'
import * as React from 'react'
import { forwardRef, Fragment, useEffect, useMemo } from 'react'
import { FrontSide } from 'three'
import { SVGLoader } from 'three-stdlib'
import { animated } from '@react-spring/three'
import { meshBounds } from '@react-three/drei'

export const CustomSvg = (
    { src, skipFill, skipStrokes, fillMaterial, strokeMaterial, fillMeshProps, strokeMeshProps, opacity, fillColor, strokeColor, strokeOp, fref ,...props }
  ) => {
    const svg = useLoader(SVGLoader, !src.startsWith('<svg') ? src : `data:image/svg+xml;utf8,${src}`)
    const strokeGeometries = useMemo(
      () =>
      skipStrokes 
      ? []
        : svg.paths.map((path) =>
          path.subPaths.map((subPath) => SVGLoader.pointsToStroke(subPath.getPoints(), path.userData.style))
          ),
      [svg, skipStrokes]
    )
    // console.log("hello")
    useEffect(() => {
      return () => strokeGeometries.forEach((group) => group && group.map((g) => g.dispose()))
    }, [strokeGeometries])
  
    return (
      <object3D ref={fref} {...props}>
        <object3D scale={[1, -1, 1]}>
          {svg.paths.map((path, p) => (
            <Fragment key={p}>
              {!skipFill &&
                SVGLoader.createShapes(path).map((shape, s) => (
                  <mesh key={s} {...fillMeshProps}>
                    <shapeGeometry args={[shape]} />
                    <animated.meshBasicMaterial
                      color={fillColor}
                      opacity={opacity}
                      transparent={true}
                      side={FrontSide}
                      depthWrite={false}
                      {...fillMaterial}
                    />
                  </mesh>
                ))}
              {!skipStrokes &&
                path.subPaths.map((_subPath, s) => (
                  <mesh key={s} geometry={strokeGeometries[p][s]} raycast={meshBounds} {...strokeMeshProps}>
                    <animated.meshBasicMaterial
                      color={strokeColor}
                      opacity={strokeOp}
                      transparent={true}
                      side={FrontSide}
                      depthWrite={false}
                      {...strokeMaterial}
                    />
                  </mesh>
                ))}
            </Fragment>
          ))}
        </object3D>
      </object3D>
    )
  }