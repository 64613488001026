import { useHelper } from "@react-three/drei";
import useMouse from "../utils/Mouse"
import { useThree, useFrame } from "@react-three/fiber"
import * as THREE from 'three'
import { useEffect, useMemo, useRef } from "react";

const ParallaxControls = () => {
    const { camera } = useThree()
    const mouse = useRef(new THREE.Vector2(0.0,0.0))
    let size = { width: 1000, height: 700 }
    useMemo(() => console.log("memo",[]))
    useFrame((state,delta) => {
        // Animate camera
        size = state.size
        const parallaxX =  Math.min(Math.max(mouse.current.x, -0.7), 0.7)
        const parallaxY = Math.min(Math.max(mouse.current.y, -0.5), 0.5)
        camera.position.x += (parallaxX - camera.position.x) * 1.4 * delta
        camera.position.y += (parallaxY - camera.position.y) * 1.4 * delta
        camera.updateProjectionMatrix() 
    })
    
    // Can remove this way of doing things if they fix drei/Html pointer capture positioning
    const handleMouseMove = (e) => {
        mouse.current.x = (e.clientX / size.width - 0.5) * 2
        mouse.current.y = (-e.clientY / size.height + 0.5) * 2
        // console.log(mouse)
    }
    useEffect(() => {
        window.addEventListener('mousemove',handleMouseMove)
        return () => {
            window.removeEventListener('mousemove', handleMouseMove)
        }
    },[])
}

export { ParallaxControls }