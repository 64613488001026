import v from './shaders/simulationVertex.glsl?raw';
import f from './shaders/simulationFragment';
import * as THREE from "three";
import { useControls } from 'leva';
import { extend, invalidate, useFrame, } from '@react-three/fiber';
import { forwardRef, useRef, useState } from 'react';
import { useMemo,memo } from 'react';
import { animated, a } from '@react-spring/three';
import { shaderMaterial } from '@react-three/drei';

export const OverShader = ({...props}) => {
  // const getMat
  const SimMat = shaderMaterial(
    props.baseUniforms,
    v,
    f,
    (e) => {
      // console.log("init shader", e)
    },
  )
  extend({SimMat})
  const Ret =  animated(({matRef,...retProps}) => {
    useFrame((state) => {
      matRef.current.uniforms.uTime.value = state.clock.elapsedTime
    })
  return <simMat key={SimMat.key} ref={matRef}
      uTime={(matRef.current == null ? 0 : matRef.current.uniforms.uTime.value)}
      uFrequency={retProps.frequency}
      x0={retProps.x0}
      x1={retProps.x1}
      y0={retProps.y0}
      y1={retProps.y1}
      z1={retProps.z1}
      uStretch={retProps.stretch}
      // uBlackHoleRad-value={0.0}
    />
    
  })
  return ( <Ret {...props}/> )
}









