import { Billboard, Html, Text } from '@react-three/drei'
import { fonts } from '../utils/States'
import { useEffect, useState } from 'react'
import { setWireframeOverride } from '@react-three/drei/materials/WireframeMaterial'
import { animated, useSpringValue, useTransition } from '@react-spring/three'

const AnimatedBillboard = animated(Billboard)
const AnimatedText = animated(Text)

const AboutContent = ({style}) => {
    const [alphaMat, set] = useState()
    const { position, opacity } = style
    return <>
        <AnimatedBillboard position={position}>
            <AnimatedText maxWidth={9} font={fonts.ralewayExtralight} fontSize={0.3} characters={"',-./4BCFISUVXacdefghijklmnoprstuvwxy"} letterSpacing={0.2} fillOpacity={opacity} sdfGlyphSize={32}>
                {"Based in Vancouver, Canada, I'm a 4th year Computing Science major at Simon Fraser University and an aspiring software developer and UX/UI designer looking to create immersive, eye-catching experiences.\n\nWhen I'm not writing code, you can find me writing fiction, exploring the BC wilderness, and crotcheting under the tutelage of my better half."}
            </AnimatedText>
            <group />
        </AnimatedBillboard>
    </>

}


export { AboutContent } 