import { extend, useFrame, useThree, createPortal, Canvas, useLoader } from "@react-three/fiber"
import {OrthographicCamera, Billboard, Select, CubicBezierLine, Segment, Segments} from "@react-three/drei"
import * as THREE from 'three'
import { Text, Svg, Line, Text3D } from "@react-three/drei"
import { useMemo, useRef, useEffect, Children, Suspense, useState } from "react"
import { states, fonts, svgs } from '../utils/States'
import { SpringValue, animated, config, useSpring, useSprings } from "@react-spring/three"
import { Leva } from "leva"


function Menu({onHomeClick, onAboutClick, onProjectsClick, onContactClick, position, index}) {
    const AnimatedText = animated(Text)
    // Handle Hovering
    let hovering = useRef(false)

    const setHover = (value) => {
        hovering = value
        document.body.style.cursor = hovering ? 'pointer' : 'auto'
    }
    
    const clicks = [onHomeClick,onAboutClick,onProjectsClick,onContactClick,]
    const text = ["Home", "About", "Projects", "Contact"]

    const [ {opacity}, api ] = useSpring({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1
        }
    },[])

    const [springs, multiApi] = useSprings(4,
        i => ({
            from: {
                letterSpace: i === 0 ? 0.3 : 0.0,
                fontSize: 0.5,
                color: i == 0 ? "#FFFFFF" : "#FFFFFF",
                lineVisible: true,
                rightPoint: i === 0 ? [-1.0,-2.8 - i,0.0] : [0.0,-2.8 - i,0.0],
                leftPoint: i === 0 ? [1.0,-2.8 - i,0.0] : [0.0,-2.8 - i,0.0],
                rotation: [0.0,0,0.0],
                emissive: i === 0 ? "#759cad" : "#FFFFFF",
                emStrength: i === 0 ? 3.5 : 0.2
                
            },
            config: {
                mass: 4,
                tension: 400,
                friction: 30,
                clamp: true
            } 
        }),
        [index]
    )
    /**
     * Handle menu animation
     */

    const handleMenuEnter = (itemIndex) => {
        setHover(true)
        multiApi.current[itemIndex].start({
                to: {
                    fontSize: 0.7,
                },
                config: {
                    clamp: false
                }
            })
        
    }
    const handleMenuLeave = (itemIndex) => {
        setHover(false)
        multiApi.current[itemIndex].start({ 
            fontSize: 0.5,
            config: {
                clamp: true
            }
        })
      
    }

    const handleMenuClick = (itemIndex) => {
        console.log("hey")
        multiApi.current.map((e, i) => {
            if (i === itemIndex) {
                e.start({
                    color: "#FFFFFF",
                    emissive: "#FFFFFF",
                    rightPoint: [1.0,-2.8 - i, 0],
                    leftPoint: [-1.0,-2.8 - i, 0],
                    emStrength: 3.5,
                    // fontSize: 0.7
                    letterSpace: 0.3
                })
            } else {
                e.start({
                    color: "#FFFFFF",
                    emissive: "#FFFFFF",
                    rightPoint: [0.0,-2.8 - i, 0],
                    leftPoint: [0.0,-2.8 - i, 0],
                    emStrength: 0.2,
                    // fontSize : 0.5
                    letterSpace: 0.0
                })
            }  
        })
        clicks[itemIndex]()
    }
    return(
        <group position={position} >
            <AnimatedText font={fonts.ralewayExtralight} letterSpacing={-0.01} fillOpacity={opacity}characters={"JetsonFer"} sdfGlyphSize={32}>
                Jetson Foster
                <animated.meshStandardMaterial color={"white"} attach={"material"}
                    transparent={false} emissive={"#FFFFFF"} emissiveIntensity={1.3}/>
            </AnimatedText>
            <AnimatedText  position={[0.0,-0.9,0.0]} font={fonts.ralewayExtralight} fontSize={0.3} fillOpacity={opacity} characters={"Designr|vlop"} sdfGlyphSize={32}>
                Designer | Developer
            </AnimatedText>
            {/* <Line points={[[-2.0,0.0,0.0],[0.0,0.0,0.0],[0.0,-2.0,0.0]]} color={'white'} lineWidth={3}/> */}
            {/* <Svg src={svgs.menuDivider} scale={0.01} position={[-1.5,-1.5,-1.0]} rotation={[0.0,0.0,- Math.PI / 2]}/>  */}
            {
                springs.map(({fontSize, letterSpace, color, leftPoint, rightPoint, emStrength, emissive},ind) => (
                        <AnimatedText key={`AnimatedText${ind}`} letterSpacing={letterSpace} fontSize={fontSize} color={color} position={[0,-2.5 - ind, 0]} font={fonts.ralewayLight} onClick={() => handleMenuClick(ind)} onPointerEnter={() => handleMenuEnter(ind)} onPointerLeave={() => handleMenuLeave(ind)}
                        sdfGlyphSize={32}>
                            {/* <meshStandardMaterial color={new THREE.Color()}/> */}
                                {text[ind]}
                                <animated.meshStandardMaterial key={`mat${ind}`} color={"white"} attach={"material"}
                             transparent={false} emissive={emissive} emissiveIntensity={emStrength}/>
                        </AnimatedText>
                ))
            }
         
        </group>
    )
}


export { Menu }