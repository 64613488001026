
import {  useFrame, useLoader } from '@react-three/fiber'
import * as React from 'react'
import { forwardRef, Fragment, useEffect, useMemo } from 'react'
import { BackSide, DoubleSide, FrontSide,ShapeGeometry } from 'three'
import { SVGLoader } from 'three-stdlib'
import { animated } from '@react-spring/three'
import { meshBounds } from '@react-three/drei'
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';
import { useRef } from 'react'

export const TagSvg = (
    { skipFill, fillMaterial, strokeMaterial, fillMeshProps, strokeMeshProps, opacity, fillColor, labels , widths, offsets,...props }
  ) => {
    const svg = useLoader(SVGLoader, `data:image/svg+xml;utf8,<svg width="10" height="10" viewBox="0 0 5 5" xmlns="http://www.w3.org/2000/svg">
    ${widths.map((w,i) => {
      return `<rect x="${offsets[i] * 10}" y="0" width="${w}" height="8" rx="4"  />`
    })}
    </svg>`)
    const geometries = useMemo(() =>svg.paths.map((path, p) => (  
          SVGLoader.createShapes(path).map((shape, s) => (
              new ShapeGeometry(shape)
          ))[0])
    ),[svg])
    useMemo(() => console.log("again"),[])
    const mergedGeo = useMemo(() => BufferGeometryUtils.mergeGeometries(geometries),[geometries])
    const Tags = () => useMemo(() => <mesh key={`${offsets[1]}`} geometry={mergedGeo}>
    <animated.meshBasicMaterial 
      color={fillColor}
      opacity={opacity.to(o => o/2)}
      transparent={true}
      side={FrontSide}
      depthWrite={false}
      {...fillMaterial}/>
  </mesh>,[mergedGeo])
  console.log(Tags())
  // useMemo(() => console.log("tagged",[]))
    useEffect(() => {
      return () => {
        mergedGeo.dispose()
        geometries.forEach(g => g.dispose())
      }
    }
      ,[geometries])
  
    // const Tags = 

    return (
      <object3D {...props} >
        <object3D scale={[1, -1, 1]}>
        <Tags key={`${offsets[1]}`}/>
        </object3D>
      </object3D>
    )
  }