import { Billboard, Html, Image, Preload, Svg, Text, meshBounds } from '@react-three/drei'
import { fonts, svgs } from '../utils/States'
import * as THREE from 'three' 
import { Fragment, Suspense, forwardRef, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { animated, config, to, useSpring, useSpringRef, useSpringValue, useTransition } from '@react-spring/three'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { CustomSvg } from './CustomSvg'
import { TagSvg } from './TagSvg'
import { useTextWidth } from '@tag0/use-text-width'
import { useControls } from 'leva'
import { CustomImg } from './CustomImg'

const AnimatedBillboard = animated(Billboard)
const AnimatedText = animated(Text)
const AnimSVG = animated(CustomSvg)
const AnimImg = animated(CustomImg)
const AnimTag = animated(TagSvg)


const projects = [
    ({style,material, globalOpacity}) => <HitchProject style={style} material={material} globalOpacity={globalOpacity}/>,
    ({style,material, globalOpacity}) => <MinervaProject style={style} material={material} globalOpacity={globalOpacity}/>,
    ({style,material, globalOpacity}) => <ActivityProject style={style} material={material} globalOpacity={globalOpacity}/>,
    ({style,material, globalOpacity}) => <WordProject style={style} material={material} globalOpacity={globalOpacity}/>
]

const ProjectsContent = ( {style} ) => {
    const [alphaMat, set] = useState()
    const [showcaseInd, setShowCase] = useState(0)
    const lastIndex = useRef(-1)
    const { position, opacity, index } = style
    const [{lColor, rColor}] = useSpring({
        from: {
            lColor: showcaseInd > 0 ? "#FFFFFF" : "#8D8D8D",
            rColor: showcaseInd < 3 ? "#FFFFFF" : "#8D8D8D",
        },
        to: {
            lColor: showcaseInd > 0 ? "#FFFFFF" : "#8D8D8D",
            rColor: showcaseInd < 3 ? "#FFFFFF" : "#8D8D8D",
        },
    
    }, [showcaseInd])
    const springRef = useSpringRef()
    const transitions = useTransition(showcaseInd, {
        ref: springRef,
        from: { opacity: 0, position: showcaseInd > lastIndex.current ? [5,0,0] : [-5,0,0] },
        enter: { opacity:  1 , position: [0,0,0], delay: 100, config: {mass: 0.5,tension: 400, friction: 50}},        
        leave: { opacity: 0, position: showcaseInd > lastIndex.current ? [-5,0,0] : [5,0,0]},
    })
    useEffect(() => {springRef.start()},[showcaseInd])
    
    const handleChevClick = (isLeft) => {
        if (isLeft && showcaseInd > 0) {
            lastIndex.current = showcaseInd 
            setShowCase(showcaseInd - 1)
        } else if (!isLeft && showcaseInd < 3) {
            lastIndex.current = showcaseInd 
            setShowCase(showcaseInd + 1)
        }
    }

        useEffect(() => {
            const goal = index == 2 ? 1 : 0
            springRef.start({
                to: {
                    opacity: goal, 
                    position: [0,0,0]}
                })
            opacity?.start(goal)
            },[index])
    return ( 
        <>
            <animated.meshStandardMaterial ref={set} opacity={opacity} color={lColor} transparent emissive={"#FFFFFF" } emissiveIntensity={0.6}/>
            <AnimatedBillboard position={position}> 
                    <ChevronButton opacity={opacity}  color={lColor} handleClick={() => handleChevClick(true)} isLeft={true} showcaseInd={showcaseInd}/>
                    <ChevronButton opacity={opacity} color={rColor} handleClick={() => handleChevClick(false)} isLeft={false} showcaseInd={showcaseInd}/>
                 {
                    transitions((style, i) => {    
                        const Project = projects[i]

                        return <Suspense>
                        <Project key={`${i}`} style={style} material={alphaMat} globalOpacity={opacity}/>

                        </Suspense>
                       
                    })
                }   
            </AnimatedBillboard>
        </>
    )
}

const ChevronButton = ({opacity, color, handleClick, isLeft, showcaseInd}) => {
    const chevPos = isLeft ? [-4,-2.48,0.0] : [11.7,-1.678,0.0]
    const boxPos = isLeft ? [-5.14,-1.35,-0.1] : [11.44,-1.35,-0.1]
    const [{boxStrokeOp}, api] = useSpring( () => ({boxStrokeOp: 0}),[])
    function onPointerMove() {
        api.start({ 
            to: isLeft && showcaseInd > 0 || !isLeft && showcaseInd < 3 ? {boxStrokeOp: 1} : {boxStrokeOp: 0 } 
        })
    }
    useEffect(() => console.log("mounted"),[])
    function onClick() {
        handleClick()
        api.start({to: isLeft && showcaseInd > 1 || !isLeft && showcaseInd < 2 ? {boxStrokeOp: 1} : {boxStrokeOp: 0 } })
    }
    function onHoverStop() {
        api.start({to: {boxStrokeOp: 0 ,}})
    }
    const rref = useRef()
    return <>
        <Suspense>
            <AnimSVG src={svgs.chevron} position={chevPos} rotation={ isLeft ? [0, 0, Math.PI] : [0,0,0]} scale={0.05} opacity={opacity} fillColor={color} skipStrokes/>
        </Suspense>
        <Suspense>
            <CustomSvg onClick={onClick} position={boxPos} src={svgs.roundedBox}  strokeColor={'#FFFFFF'} scale={0.03} strokeOp={boxStrokeOp} opacity={0} onPointerMove={onPointerMove} onPointerLeave={onHoverStop} skipFill/>
        </Suspense>
    </>
}

const ToolTags = ({ labels, position, tagOffsets, tagWidths, opacity, material}) => {
    // const {a,b,c } = useControls({
    //     a: {
    //         value: 7.25,
    //         min:5,
    //         max:10
    //     },
    //     b: {
    //         value: 1.54,
    //         min:0,
    //         max:2
    //     },
    //     c: {
    //         value: 0,
    //         min:-5,
    //         max:5
    //     },
    // })
    const {color1, color2} = useControls({
        color1: {value: "#038d84"},
        color2: {value: "#294f60"},
    })
    return ( 
        <animated.group position={position}>
                    <Suspense>
            {
                labels.map((label, i) => {
                return <group key={`label${i}`} position={[tagOffsets[i],0,0]}>
                        <AnimatedText font={fonts.ralewayMedium} fontSize={3.5} anchorX={"left"} position-x={-0} characters={label} color={color1} fillOpacity={opacity} scale={0.1} sdfGlyphSize={16}>
                            {label}
                        </AnimatedText>
                </group>
                })
            } 
                    <TagSvg opacity={opacity} scale={0.1} fillColor={color2}  position={[-0.22,0.38,-0.01]} widths={tagWidths} offsets={tagOffsets} labels={labels}/> 
                    </Suspense>
                <Suspense>
                {
                }
                </Suspense>
        </animated.group>)
}

const HitchProject = ( { style, material, globalOpacity } ) => {
    console.log("globalOpacity",globalOpacity)
    const labels = ["Kotlin", "Java", "Android", "Firebase ", "MapBox API", "SFU"]
    const tagOffsets = [-1.02,0.45,1.8,3.71,5.63,8.14]
    const tagWidths = [13.5,12.4,18,18.2,24,11.5]
    console.log("mounting hitch")
    useMemo(() => console.log("memo"),[])
    return ( material &&
        <AnimatedBillboard position={style.position} >
            <Suspense>
                <AnimImg url='img/uber.png' position={ [-2.5,0.0,0.0] } scale={[2,4]} opacity={style.opacity} transparent color={'#F0F0F0'}/>
                <AnimImg url='img/uber2.png' position={ [1.5,0,0] } scale={[2,4]} opacity={style.opacity}  transparent/>
            </Suspense>
            <Suspense>
                <AnimatedText font={fonts.ralewayExtralight} color={"#FFFFFF"} fillOpacity={style.opacity}  fontSize={0.3} material={material} anchorX={"left"} anchorY={"top"} position={[4,0.5,0]} maxWidth={7.5} letterSpacing={0.2} sdfGlyphSize={32}>
                {"A carpooling app designed to build communities around good will and merit. \n\nGenerous drivers post their day's scheduled trips for passengers to browse and request pickup / dropoff modifications to. \n\nThe perfect way to find companionship while lowering one's carbon footprint."}
                </AnimatedText>
            </Suspense>
            <ToolTags labels={labels} position={[-1,-4.65,0.0]} opacity={style.opacity}  tagOffsets={tagOffsets} tagWidths={tagWidths} material={material}/>
        </AnimatedBillboard>)
    
}
const MinervaProject = ({style, material, globalOpacity}) => {
    const labels = ["AWS Cloud Services", "React", "ChatGPT", "TailwindCSS", "SFU"]
    const tagOffsets = [-1,2.925,4.45,6.47,9.1]
    const tagWidths = [38,13.85,19,25,11.5]
    return (material &&
        <AnimatedBillboard position={style.position} >
            <Suspense>
                <AnimImg url='img/uber.png' position={ [-2.5,0.0,0.0] } scale={[2,4]} opacity={style.opacity} transparent/>
                <AnimImg url='img/uber2.png' position={ [1.5,0,0] } scale={[2,4]} opacity={style.opacity}  transparent/>
            </Suspense>
        <Suspense>
            <AnimatedText material={material} font={fonts.ralewayExtralight} color={"#FFFFFF"} fillOpacity={style.opacity} maxWidth={7.5} position={[4,0.5,0]} letterSpacing={0.2} fontSize={0.3} anchorX={"left"} anchorY={"top"} characters={",./ABCDEGIPRSTW\abcdefghilmnopqrstuvwxy"} sdfGlyphSize={32}>  
                {"A web app that leverages ChatGPT to help educators generate content for their class. Built in the microservice architecture, user data, authentication, and AI query are exposed via REST API, and powered by AWS Cognito, AWS API gateway, and AWS lambda. \n\nDeveloped and deployed in the Continuous Integration / Continuous Development style."}
            </AnimatedText>
        </Suspense>
        <ToolTags labels={labels} position={[-1,-4.65,0.0]} tagOffsets={tagOffsets}  tagWidths={tagWidths} opacity={style.opacity} material={material}/>
        </AnimatedBillboard>
    )
}
const ActivityProject = ({style, material, globalOpacity}) => {
    const labels = ["Kotlin", "Java", "Android", "WEKA ML", "Google Maps", "SFU"]
    const tagOffsets = [-1,0.54,1.87,3.8,6.02,8.78]
    const tagWidths = [14,12,18,21,26.5,12]
    return (material &&
        <AnimatedBillboard position={style.position} >
            <Suspense>
                <AnimImg url='img/uber.png' position={ [-2.5,0.0,0.0] } scale={[2,4]} opacity={style.opacity} transparent/>
                <AnimImg url='img/uber2.png' position={ [1.5,0,0] } scale={[2,4]} opacity={style.opacity}   transparent/>
            </Suspense>
        <Suspense>
            <AnimatedText font={fonts.ralewayExtralight} color={"#FFFFFF"} fillOpacity={style.opacity}  maxWidth={7.5} position={[4,0.5,0]} letterSpacing={0.2} fontSize={0.3} anchorX={"left"} anchorY={"top"} characters={"'.AEKSTWabcdefghiklmnoprstuvwy"} sdfGlyphSize={32}>
                {"An android app for tracking physical activity information. Session data is recorded for later viewing in the records tab. \n\nThe type of activity is automatically discerned using a WEKA machine learning model and the phone's accelerometer data."}
            </AnimatedText>
        </Suspense>
            <ToolTags labels={labels} position={[-1,-4.65,0.0]} opacity={style.opacity} tagOffsets={tagOffsets}  tagWidths={tagWidths} material={material}/>
        </AnimatedBillboard>
    )
}
const WordProject = ({style, material, globalOpacity}) => {
    const labels = ["Java", "Datamuse", "retrofit", "Android", "SFU"]
    const tagOffsets = [-1,0.33,2.61,4.265,6.2]
    const tagWidths = [12,21.5,15.2,18,12]
    return (material &&
        <AnimatedBillboard position={style.position} >
            <Suspense>
                <AnimImg url='img/uber.png' position={ [-2.5,0.0,0.0] } scale={[2,4]} opacity={style.opacity} transparent/>
                <AnimImg url='img/uber2.png' position={ [1.5,0,0] } scale={[2,4]} opacity={style.opacity}   transparent/>
            </Suspense>
        <Suspense>
            <AnimatedText font={fonts.ralewayExtralight} color={"#FFFFFF"} fillOpacity={style.opacity}  maxWidth={7.5} position={[4,0.5,0]} letterSpacing={0.2} fontSize={0.3} anchorX={"left"} anchorY={"top"} sdfGlyphSize={32}>
                {"Fun to make, and fun to play, WordSummit is a game about using synonyms and homonyms to scale a mountain.\n\nMade start to finish in 24h for Mountain Madness Hackathon 2022."}
            </AnimatedText>
        </Suspense>
            <ToolTags labels={labels} position={[-1,-4.65,0.0]} tagOffsets={tagOffsets}  tagWidths={tagWidths} opacity={style.opacity}material={material}/>
        </AnimatedBillboard>)
}




export { ProjectsContent } 