
import ReactDOM from 'react-dom/client'
import Experience from './Experience'
import './index.css'
import useMouse from './utils/Mouse'
import { ParallaxControls } from './controls/ParallaxControls'
import { OrbitControls, Html, Bvh, PerformanceMonitor, Preload, meshBounds } from '@react-three/drei'
import { Bloom, DepthOfField, EffectComposer, Noise, SelectiveBloom, Vignette } from '@react-three/postprocessing'
import { BlendFunction, BoxBlurPass, KernelSize, OverrideMaterialManager } from 'postprocessing'
import { extend, Canvas, useFrame, useThree} from '@react-three/fiber'
import { Color, CameraHelper } from 'three'
import { StrictMode, Suspense, useEffect, useMemo, useRef, useState } from 'react'
import { Loading } from './Loading'
import { Perf } from 'r3f-perf'
import { Leva, useControls } from 'leva'
extend({ OrbitControls })

const Colormaker = () => {
    console.log(0.5 * (1.0 / Math.pow(devicePixelRatio, 1.4)))
    const {bgColor} = useControls({ 
        bgColor: {
            value: "#021b24"
        },
        fogColor: {
            value: "#021b24"
        }
     })
    return <>
            <color attach={'background'} args={["#021b24"]}/> 
            {/* <fog attach={'fog'} color={fogColor} near={10} far={20}/> */}
        </> 
    }

export default function App() {
return (
    <>
        <Leva hidden/>
        <Canvas gl={{powerPreference: "high-performance", pixelRatio: devicePixelRatio}} dpr={devicePixelRatio} raycaster={meshBounds}>
            <color key={"canvas_bg_color"} attach={'background'} args={["#021b24"]}/> 
            <ParallaxControls />
            {/* <OrbitControls/> */}
            {/* <PerformanceMonitor flipflops={3} onFallback={() => setDpr(1)}/> */}
            <Perf logsPerSecon={0.5} />
            <EffectComposer disableNormalPass multisampling={1}>
                <SelectiveBloom
                    luminanceThreshold={0.0}
                    luminanceSmoothing={0.0}
                    intensity={0.5} 
                    kernelSize={KernelSize.LARGE}
                    />
                    
                <Noise opacity={0.02 / devicePixelRatio}/>
                <Vignette offset={0.1} darkness={0.3}/>
            </EffectComposer>
            <Experience/>
        </Canvas>
    </>
)}