import { extend, useFrame, useThree, createPortal, Canvas } from "@react-three/fiber"
import { Bloom, DepthOfField, EffectComposer, Noise, Glitch, Select } from '@react-three/postprocessing'
import { GlitchMode } from 'postprocessing'
import { Cloud, Sky, Stars, useCursor, useFBO, OrbitControls, FirstPersonControls, OrthographicCamera, useHelper, useKeyboardControls } from "@react-three/drei"
import * as THREE from 'three'
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import v from './shaders/vertex.glsl?raw'
import f from './shaders/fragment.glsl?raw' 
import { useControls } from 'leva'
import { useSpring, animated, useSpringRef, Controller, Trail, config, SpringRef } from '@react-spring/three' 
import { OverShader } from "./SimulationMaterial"
import { states, pointSpringConfigs, } from "./utils/States"


const getUniforms = () => { 
    const width = 512
    const height = 512
    const length = width * height * 4 
    const data = new Float32Array(length);
    
    let time = performance.now()
    for (let i = 0; i < length; i++) {
      const stride = i * 4;
      
      const distance = Math.sqrt(Math.random() - 0.5) * 2.0; 
      const theta = THREE.MathUtils.randFloatSpread(360); 
      const phi = THREE.MathUtils.randFloatSpread(360); 
      data[stride] =  distance * Math.sin(theta) * Math.cos(phi)
      data[stride + 1] =  distance * Math.sin(theta) * Math.sin(phi);
      data[stride + 2] =  distance * Math.cos(theta);
      data[stride + 3] =  1.0; // Will be used for black hole horizon strength
    // const distance = Math.random()
    //   data[stride] =  distance * Math.random()
    //   data[stride + 1] =  distance * Math.random();
    //   data[stride + 2] =  distance * Math.random();
    //   data[stride + 3] =  1.0; // Will be used for black hole horizon strength
    }
    console.log("generation", performance.now() - time)
    const texture = new THREE.DataTexture(
        data,
        512,
        512,
        THREE.RGBAFormat,
        THREE.FloatType,
    )
    // const texture = getTexture()
    return {
      uFrequency: 0.10,
        x0: new THREE.Vector3(0, 0, 1),
        x1: new THREE.Vector3(0.0,1.1,1.1),
        y0: new THREE.Vector3(1.1,0.0,1.1),
        y1: new THREE.Vector3(1.1,0.0,1.1),
        z1: new THREE.Vector3(1.1,1.1,0.0),
        uStretch: new THREE.Vector3(4.0,1.0,1.0),
        positions: texture,
        uTime: 0.0 ,
        uRot:  new THREE.Vector3(1.0,1.0,1.0) ,
        uCamPos: new THREE.Vector3(0.0,0.0,0.0) ,
        uBlackHoleRad:  0.0 ,
    }
  }
  
  export const FBOParticles = ({index, isVisible}) => {
    const baseUniforms =  useMemo(() => getUniforms(),[])
    const [ toggleReset, setReset ] = useState(false)
    const needsInit = useRef(true)
    const lastIndex = useRef(-1)
    const isLooping = useRef(false)
    const pointsRef = useRef()
    const simulationMaterialRef = useRef()
    const shouldLoop = useRef(false)
    const size = 512;

    // useEffect(() => {
    //     lastIndex.current = index
    // },[index])

    // Create a camera and a scene for our FBO
    const scene = new THREE.Scene();
    const componentCamera = new THREE.OrthographicCamera(
        -1,
        1,
        1,
        -1,
        1 / Math.pow(2, 53),
        10
    );
    

    // Create a simple square geometry with custom uv and positions attributes
    const positions = new Float32Array([
        -1,
        -1,
        0,
        1,
        -1,
        0,
        1,
        1,
        0,
        -1,
        -1,
        0,
        1,
        1,
        0,
        -1,
        1,
        0,
    ]);
    const uvs = new Float32Array([0, 1, 1, 1, 1, 0, 0, 1, 1, 0, 0, 0]);
    
    // Create our FBO render target
    const renderTarget = useFBO(size, size, {
        minFilter: THREE.NearestFilter,
        magFilter: THREE.NearestFilter,
        format: THREE.RGBAFormat,
        stencilBuffer: false,
        type: THREE.FloatType,
        
    });
    
    // ~ 5ms
    const particlesPosition = useMemo(() => {
        const length = size * size;
        const particles = new Float32Array(length * 3);
        for (let i = 0; i < length; i++) {
        let i3 = i * 3;
        particles[i3 + 0] = (i % size) / size;
        particles[i3 + 1] = i / size / size;
        }
        return particles;
    }, [size]);

    const {color} = useControls({
        color: {
            value: "#759cad"
        }
    })
    const uniforms = useMemo(
        () => ({
        uPositions: {
            value: null,
        },
        uBlackHoleRad: {
            value: 0.0,
        },
        uTime: {
            value: 0.0,
        },
        uPixelRatio: {
            value: devicePixelRatio,
        },
        uColor: {
            value: new THREE.Color(color)
        }
        }),
        []
    )
    useFrame((state) => {
        const { gl, clock } = state;
        // Set the current render target to our FBO
        gl.setRenderTarget(renderTarget);
        gl.clear();
        // Render the simulation material with square geometry in the render target
        gl.render(scene, componentCamera);
        // Revert to the default render target
        gl.setRenderTarget(null);
        // camera.getWorldDirection(myVec)
        // simulationMaterialRef.uRot.value = componentCamera.worldToLocal(myVec)
        // camera.getWorldPosition(myVec2)
        // simulationMaterialRef.current.uniforms.uCamPos.value = componentCamera.worldToLocal(myVec2)

        if (pointsRef.current != null) {
            pointsRef.current.material.uniforms.uPositions.value = renderTarget.texture;
            pointsRef.current.material.uniforms.uTime.value = clock.elapsedTime;
        }
    });
    function getNewSpringState(isPassive) {
        let ret = null
        switch (index) {
            case 0:
                ret = states.home(isPassive, needsInit.current)
                break
            case 1:
                ret = states.about(isPassive, needsInit.current)
                break
            case 2:
                ret = states.projects(isPassive, needsInit.current)
                break
            case 3:
                ret = states.contact(isPassive, needsInit.current)
            }
        if (lastIndex.current != index) {
            isLooping.current = false
        }
        lastIndex.current = index
        needsInit.current = false
        return ret
    }
    const stuff = (e,s) => {
        if (!shouldLoop.current)  {
            isLooping.current = true
            shouldLoop.current = true
        }
        setReset(!toggleReset)
    }
    
    // useEffect(() => console.log("toggled", toggleReset),[toggleReset])

    const [ springs, api] =  useSpring( isVisible ?  
        {
            onRest: (e,s) => {
                e.finished && !isLooping.current && springs.pointsPosition.idle ? stuff(e,s) : {} 
            },
            loop: check(),
            ...getNewSpringState(index === lastIndex.current)
        } : {}, [index, isVisible, toggleReset])

    function check() {
        if (shouldLoop.current && lastIndex.current === index) {
            shouldLoop.current = false
            return { reverse: true }
        }
         return false
    }
    

    return ( isVisible && <Select enabled>
        {/* Render off-screen our simulation material and square geometry */}
        {createPortal(
            <animated.mesh >
                <OverShader 
                matRef={simulationMaterialRef}
                size={size}
                frequency={springs.frequency}
                x0z={springs.x0}
                x1={springs.x1}
                y0={springs.y0}
                y1={springs.y1}
                z1={springs.z1}
                stretch={springs.stretch}
                blackHoleRad={springs.blackHoleRad}
                rot={springs.rot}
                camPos={springs.camPos}
                baseUniforms={baseUniforms}
                // props={{size,simulationMaterialRef,...springs}}
                />
                <bufferGeometry>
                    <bufferAttribute
                    attach="attributes-position"
                    count={positions.length / 3}
                    array={positions}
                    itemSize={3}
                    />
                    <bufferAttribute
                    attach="attributes-uv"
                    count={uvs.length / 2}
                    array={uvs}
                    itemSize={2}
                    />
                </bufferGeometry>
            </animated.mesh>,
            scene
        )}
        <animated.points ref={pointsRef} position={ springs.pointsPosition }>
            <bufferGeometry>
            <bufferAttribute
                attach="attributes-position"
                count={particlesPosition.length / 3}
                array={particlesPosition}
                itemSize={3}
            /> 
            </bufferGeometry>
            <shaderMaterial
            blending={THREE.AdditiveBlending}
            depthWrite={false}
            fragmentShader={f}
            vertexShader={v}
            uniforms={uniforms}
            />
        </animated.points>
        </Select>
    );
}


