import { act } from "@react-three/fiber";
import { Vector3 } from "three";

const delta = 0.4

const initConfig = {
    mass: 15,
    tension: 120,
    friction: 400,
    restVelocity: 0.1,
    precision: 0.0005,
}

const spacialConfig = {
    mass: 10,
    tension: 240,
    friction: 100,
    restVelocity: 0.1,
    precision: 0.0005,
}

const coeffConfig = {
    mass: 10,
    tension: 278,
    friction: 125,
    restVelocity: 0.1,
    precision: 0.0005,
}

const handleConfig = (key,passive, init) => {
    if (init) return initConfig
    if (passive) {
        return  {
            mass: 1,
            tension: 150,
            friction: 300,
            precision: 0.0001,
            restVelocity: 0.1,
            clamp: true
        }
    }
    switch (key) {
        case 'frequency':
        case 'x0':
        case 'x1':
        case 'y0':
        case 'y1':
        case 'z1':
        case 'blackHoleRad':
        case 'rot':
        case 'camPos':
            return coeffConfig
        case 'stretch':
        case 'pointsPosition':
            return spacialConfig
    }
}

const newStates = {

}

const states = {
    /**
    * Home
    */ 
    home: (passive, init) => { return  {
        from: passive ? {
            frequency: 0.33,
            stretch: [ 3.0, 2.01, 1.00],
            x0: [0.0, 0.0, 0.43],
            x1: [ 0.0, 1.35, 0.73],
            y0: [ 0.88, 0.0, 0.34],
            y1: [ 0.65, 0.0, 0.45],
            z1: [ 0.77, 1.50, 0.0 ],
            pointsPosition: [3.7, 0.0, -4.0],
        } : init ? {
            frequency: 0,
            stretch: [ 3.0, 2.01, 1.00],
            x0: [0.0, 0.0, 0.43],
            x1: [ 0.0, 1.35, 0.73],
            y0: [ 0.88, 0.0, 0.34],
            y1: [ 0.65, 0.0, 0.45],
            z1: [ 0.77, 1.50, 0.0 ],
            pointsPosition: [-1.5, 0.0, 3.0],
        } : {},
        to: passive ?  
        
            // {
            //     frequency: 0.25,
            //     stretch: [ 3.0, 2.01, 1.00],
            //     x0: [0.0, 0.0, -0.43],
            //     x1: [ 0.0, 0, -0.73],
            //     y0: [ 0.40, 0.0, 0.20],
            //     y1: [ 2.0, 0.0, 0.20],
            //     z1: [ -0.77, 1.50, 0.0 ],
            //     pointsPosition: [3.7, 0.0, -4.0],
            // },
            {
                frequency: 0.20,
                stretch: [ 3.0, 2.01, 1.00],
                x0: [0.0, 0.0, -1.0],
                x1: [ 0.0, -0.35, 0.73],
                y0: [ 0.88, 0.0, -1.0],
                y1: [ 2.3, 0.0, -0.45],
                z1: [ 0.80, 1.75, 0.0 ],
                pointsPosition: [3.7, 0.0, -4.0],
            }
            // {
            //     frequency: 0.28,
            //     stretch: [ 3.0, 2.01, 1.00],
            //     x0: [0.0, 0.0, 0.1],
            //     x1: [ 0.0, 1.5, 0.73],
            //     y0: [ 1.2, 0.0, 0.34],
            //     y1: [ 1.2, 0.0, 1.1],
            //     z1: [ 0.95, 1.80, 0.0 ],
            //     pointsPosition: [3.7, 0.0, -4.0],
            // },
            // {
            //     frequency: 0.35,
            //     stretch: [ 3.0, 2.01, 1.00],
            //     x0: [0.0, 0.0, 0.43],
            //     x1: [ 0.0, 1.35, 0.73],
            //     y0: [ 0.88, 0.0, 0.34],
            //     y1: [ 0.65, 0.0, 0.45],
            //     z1: [ 0.77, 1.50, 0.0 ],
            //     pointsPosition: [3.7, 0.0, -4.0],
            // }
        
        : {
                frequency: 0.33,
                stretch: [ 3.0, 2.01, 1.00],
                x0: [0.0, 0.0, 0.43],
                x1: [ 0.0, 1.35, 0.73],
                y0: [ 0.88, 0.0, 0.34],
                y1: [ 0.65, 0.0, 0.45],
                z1: [ 0.77, 1.50, 0.0 ],
                pointsPosition: [3.7, 0.0, -4.0],
            } ,
        config: (key) => { return handleConfig(key, passive, init) }, 
    }},
    /**
     * About
     */
    about: (passive, init) =>  { return {
        from: passive ? {
            frequency: 0.25,
            stretch: [ 1.0, 2.2, 1.00],
            x0: [0.0, 0.0, -2.2],
            x1: [ 0.0, -0.66, -0.06],
            y0: [ -3.08, 0.0, -3.42],
            y1: [ -0.71, 0.0, -2.54],
            z1: [ 0.71, 1.32, 0.0 ],
            pointsPosition: [8.2, 0.0, -4.0]
        } : {},
        to: passive ? //[
            // {   
            //     frequency: 0.35,
            //     x0: [0.0, 0.0, 0.7],
            //     x1: [ 0.0, 4.0, 2.0],
            //     y0: [ 1.0, 0.0, 1.3],
            //     y1: [ 1.0, 0.0, 0.9],
            //     z1: [ 1.9, -0.7, 0.0 ],
            // },
            {
                frequency: 0.4,
                x0: [0.0, 0.0, -3.43],
                x1: [ 0.0, -0.66, .05],
                y0: [ -3.08, 0.0, -3.42],
                y1: [ -0.71, 0.0, -2.54],
                z1: [ 2.45, 2.82, 0.0 ],
            }
            // {
            //     frequency: 0.20,
            //     x0: [0.0, 0.0, 0.6],
            //     x1: [ 0.0, 3.0, 1.0],
            //     y0: [ 3.0, 0.0, -1.3],
            //     y1: [ 3.0, 0.0, 0.9],
            //     z1: [ 2.5, -0.9, 0.0 ],
            // },
            // {
            //     frequency: 0.25,
            //     x0: [0.0, 0.0, 0.6],
            //     x1: [ 0.0, 3.0, 1.0],
            //     y0: [ -1.0, 0.0, 1.3],
            //     y1: [ -1.0, 0.0, 0.9],
            //     z1: [ 1.9, -0.7, 0.0 ],
            // }
        // ]
         :  {
                frequency: 0.25,
                stretch: [ 1.0, 2.2, 1.00],
                x0: [0.0, 0.0, -2.2],
                x1: [ 0.0, -0.66, -0.06],
                y0: [ -3.08, 0.0, -3.42],
                y1: [ -0.71, 0.0, -2.54],
                z1: [ 0.71, 1.32, 0.0 ],
                pointsPosition: [8.2, 0.0, -4.0],
            },
        config: (key) => { return handleConfig(key, passive, init)}
    }},
    /**
     * Projects
     */
    projects: (passive, init) => { return {
        from: passive ? {
            frequency: 0.25,
            stretch: [ 3.7,  0.70,  1.20],
            x0: [0.0,  0.0,  0.68],
            x1: [ 0.0,  1.89,  2.00],
            y0: [ 1.79,  0.0,  0.83],
            y1: [ -0.01,  0.0,  0.12],
            z1: [ 0.57,  1.83,  0.0 ],
            pointsPosition: [4.8, 4.0, -5.2],
        } : {},
        to: passive ? 
            // {
            //     frequency: 0.33,
            //     x0: [0.0,  0.0,  0.68],
            //     x1: [ 0.0,  1.89,  2.00],
            //     y0: [ 1.79,  0.0,  0.83],
            //     y1: [ -0.01,  0.0,  0.12],
            //     z1: [ 0.57,  1.83,  0.0 ],
            //     pointsPosition: [4.8, 4.0, -5.2],
            // },
            // {
            //     frequency: 0.25,
            //     x0: [0.0,  0.0,  0.68],
            //     x1: [ 0.0,  1.89,  2.00],
            //     y0: [ 1.79,  0.0,  0.83],
            //     y1: [ -0.01,  0.0,  0.12],
            //     z1: [ 0.57,  1.83,  0.0 ],
            // },
            {
                frequency: 0.33,
                x0: [0.0,  0.0,  0.68],
                x1: [ 0.0,  1.89,  2.00],
                y0: [ 1.79,  0.0,  0.83],
                y1: [ -0.01,  0.0,  0.12],
                z1: [ 0.57,  1.83,  0.0 ],
            }
            // {
            //     frequency: 0.25,
            //     x0: [0.0,  0.0,  0.68],
            //     x1: [ 0.0,  1.89,  2.00],
            //     y0: [ 1.79,  0.0,  0.83],
            //     y1: [ -0.01,  0.0,  0.12],
            //     z1: [ 0.57,  1.83,  0.0 ],
            // },
         :  {
                frequency: 0.25,
                stretch: [ 3.7,  0.70,  1.20],
                x0: [0.0,  0.0,  0.68],
                x1: [ 0.0,  1.89,  2.00],
                y0: [ 1.79,  0.0,  0.83],
                y1: [ -0.01,  0.0,  0.12],
                z1: [ 0.57,  1.83,  0.0 ],
                pointsPosition: [4.8, 4.0, -5.2],
            } ,
        // loop: true,
        config: (key) => { return handleConfig(key, passive, init) }
    }},
    /**
     * Contact
     */
    contact: (passive, init) => { return {
        from: passive ? {
            frequency: 0.25,
                stretch: [ 3.7,  0.82,  1.20],
                x0: [0.0,  0.0,  0.68],
                x1: [ 0.0,  1.89,  2.00],
                y0: [ 1.79,  0.0,  0.83],
                y1: [ -0.01,  0.0,  0.12],
                z1: [ 0.57,  1.83,  0.0 ],
                pointsPosition: [4.8, 4.1, -5.2]
        } : {},
        to: passive ?  
            {
                frequency: 0.25,
                stretch: [ 3.7,  0.82,  1.20],
                x0: [0.0,  0.0,  0.68],
                x1: [ 0.0,  1.89,  2.00],
                y0: [ 1.79,  0.0,  0.83],
                y1: [ -0.01,  0.0,  0.12],
                z1: [ 0.57,  1.83,  0.0 ],
                pointsPosition: [4.8, 4.1, -5.2],
            } : [
                {

                }
            ]
        ,
        // loop: true,
        config: (key) => { return handleConfig(key, passive, init) }
    }}
}

const fonts = {
    ralewayExtralight:  "fonts/raleway.extralight.ttf",
    ralewayLight:  "fonts/raleway.light.ttf",
    ralewayBold:  "fonts/raleway.bold.ttf",
    ralewayRegular:  "fonts/raleway.regular.ttf",
    ralewaySemibold:  "fonts/raleway.semibold.ttf",
    ralewayMedium:  "fonts/raleway.medium.ttf",
}
const pointSpringConfigs = {
    spacial : (velocity) => {
        return {
            mass: 1,
            tension: 350,
            friction: 100,
            restVelocity: 0.1,
            precision: 0.0005,
            velocity: velocity,
        }
    },
    coefficients: (velocity) =>{
        return {
            mass: 1,
            tension: 278,
            friction: 125,
            restVelocity: 0.1,
            precision: 0.0005,
            velocity: velocity,

        }
    },
    init: () => {
        return {
            mass: 15,
            tension: 120,
            friction: 400,
            restVelocity: 0.1,
            precision: 0.0005,
        }
    }
}




const menu = {
    openConfig: {}

}

const svgs = {
    menuDivider: "svg/menuDivider.svg",
    chevron: "svg/chevron-right.svg",
    roundedBox: "svg/roundedSquare.svg"
}

export { states, fonts, svgs, pointSpringConfigs}
// x0: (0.0, 0.0, 0.43),
// x1: new Vector3(0.0, 1.35, 0.73),
// y0: new Vector3(0.88, 0.0, 0.34),
// y1: new Vector3(0.65, 0.0, 0.45),
// z1: new Vector3(0.77, 1.5, 0.0),
// uStretch: new Vector3(3.0, 2.0, 1.00),