import glslCurlNoise from './glslCurlNoise.glsl?raw';
import blackHole from './blackHole.glsl?raw';

const fragmentShader = `

uniform float uFrequency;
uniform vec3 uStretch;
uniform vec3 x0;
uniform vec3 x1;
uniform vec3 y0;
uniform vec3 y1;
uniform vec3 z1;
uniform sampler2D positions;
uniform float uTime;
uniform vec3 uRot;
uniform vec3 uCamPos;
uniform float uBlackHoleRad;
varying vec2 vUv;

${glslCurlNoise}
${blackHole}

void main() {

  // float k = z1.w;

  vec3 curlPos = texture2D(positions, vUv).rgb;

  curlPos = customNoise(curlPos * uFrequency + uTime * 0.001, uTime, x0, x1, y0, y1, z1,  uStretch);
  curlPos += customNoise(curlPos * uFrequency * 2.0, uTime, x0, x1, y0, y1, z1,  uStretch) * 2.0;

  
  // curlPos += displaceFromLineSphere(curlPos, uCamPos, uRot, uBlackHoleRad );

  // float ringClosenessStrength = getRingClosenessStrength(curlPos, uCamPos, uRot, uBlackHoleRad) * clamp(distance(uCamPos,curlPos) - 7.0,0.0,1.0);
  float ringClosenessStrength = 0.0;
  
  gl_FragColor = vec4(curlPos, ringClosenessStrength);

  
}
`

export default fragmentShader
